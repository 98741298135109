import React from 'react';

import { Margins, Heading, List } from '../../../components';
import { useTranslation } from '../../../hooks';
import * as style from './Content.module.css';

const Content = () => {
  const { translations } = useTranslation();

  const firstColumnKids = (translations.DIAGNOSES.KIDS?.DIAGNOSES || []).filter(
    (_, index) => index < translations.DIAGNOSES.KIDS?.DIAGNOSES.length / 2,
  );
  const secondColumnKids = (
    translations.DIAGNOSES.KIDS?.DIAGNOSES || []
  ).filter(
    (_, index) => index >= translations.DIAGNOSES.KIDS?.DIAGNOSES.length / 2,
  );

  const firstColumn = translations.DIAGNOSES.GROWNUPS.DIAGNOSES.filter(
    (_, index) => index < translations.DIAGNOSES.GROWNUPS.DIAGNOSES.length / 2,
  );
  const secondColumn = translations.DIAGNOSES.GROWNUPS.DIAGNOSES.filter(
    (_, index) => index >= translations.DIAGNOSES.GROWNUPS.DIAGNOSES.length / 2,
  );

  return (
    <Margins size="2x">
      {firstColumnKids.length > 0 && (
        <Margins>
          <Heading level="h4" uppercase color="secondary">
            {translations.DIAGNOSES.KIDS?.TITLE}
          </Heading>
          <div className={style['content']}>
            <Margins>
              {firstColumnKids.map((item, index) => (
                <Margins size="05x" key={index}>
                  <Heading level="h5" highlight>
                    {item.TITLE}
                  </Heading>
                  <List items={item.TEXTS} size="small" />
                </Margins>
              ))}
            </Margins>
            <Margins>
              {secondColumnKids.map((item, index) => (
                <Margins size="05x" key={index}>
                  <Heading level="h5" highlight>
                    {item.TITLE}
                  </Heading>
                  <List items={item.TEXTS} size="small" />
                </Margins>
              ))}
            </Margins>
          </div>
        </Margins>
      )}

      <Margins>
        <Heading level="h4" uppercase color="secondary">
          {translations.DIAGNOSES.GROWNUPS.TITLE}
        </Heading>
        <div className={style['content']}>
          <Margins>
            {firstColumn.map((item, index) => (
              <Margins size="05x" key={index}>
                <Heading level="h5" highlight>
                  {item.TITLE}
                </Heading>
                <List items={item.TEXTS} size="small" />
              </Margins>
            ))}
          </Margins>
          <Margins>
            {secondColumn.map((item, index) => (
              <Margins size="05x" key={index}>
                <Heading level="h5" highlight>
                  {item.TITLE}
                </Heading>
                <List items={item.TEXTS} size="small" />
              </Margins>
            ))}
          </Margins>
        </div>
      </Margins>
    </Margins>
  );
};

export default Content;
