import React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Seo } from '../components';
import { useTranslation } from '../hooks';
import { Content } from '../containers/Diagnoses';

const Diagnoses = ({ pageContext: { currentPath } }) => {
  const { t, locale } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t('SEO.DIAGNOSES.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container tag="section">
        <Content />
      </Container>
    </Layout>
  );
};

export default Diagnoses;
